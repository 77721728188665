import { createContext } from "react";
import { useFetch } from "../hook/useFetch";
export type ContextType = {
  poets: any[] | undefined;
  poems: any[] | undefined;
  // mappingPoets: any;
};

export const GeneralContext = createContext<ContextType>({ poets: [], poems: [] });

export const GeneralContextProvider = ({ children }: any) => {
  const poets = useFetch("poets");
  const poems = useFetch("poems");
  // const mappingPoets: any = useMemo(() => createMapping(poets, "id"), [poets]);

  return (
    <GeneralContext.Provider value={{ poets, poems }}>
      {children}
    </GeneralContext.Provider>
  );
};

import { makeStyles } from "../../helper/theme";

export const useStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: _theme.dark[40],
    paddingBottom: 200,
  },
  divider: {
    "&::before": {
      borderTopColor: _theme.white,
    },
    "&::after": {
      borderTopColor: _theme.white,
    },
    marginBottom: "1rem",
  },
}));

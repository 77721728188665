import { default as createCache } from "@emotion/cache";
import { ThemeProvider } from "@mui/material";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { TssCacheProvider } from "tss-react";
import { GlobalStyles } from "./components/GlobalStyles/GlobalStyles";
import { Head } from "./components/Head/Head";
import { Header } from './components/Header/Header';
import { HomePage } from './components/HomePage/HomePage';
import { GeneralContextProvider } from "./context/GeneralContext";
import { createRoute } from './helper/createRoute';
import { theme } from "./helper/theme";
import Sair from "./sair/sair";
import Siir from "./siir/siir";

const cache = createCache({
  "key": "sahinyazilim"
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const mainRoutes = createRoute([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "sair/:link",
    element: <Sair />,
  },
  {
    path: "siir/:link",
    element: <Siir />,
  },
]);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Head>
        <title>KAFİYE - ŞİİR KÜTÜPHANESİ</title>
        <meta name="description" content="Şiir severlerin buluşma noktası" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <TssCacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <GeneralContextProvider>
            <GlobalStyles />
            <Header />
            {mainRoutes}
          </GeneralContextProvider>
        </ThemeProvider>
      </TssCacheProvider>
    </BrowserRouter>
  </React.StrictMode>
);

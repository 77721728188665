import { makeStyles } from "../../helper/theme";

export const useStyles = makeStyles()((_theme) => ({
  card: {
    display: "flex",
    padding: "1rem",
    borderRadius: "0.5rem",
    marginBottom: "1rem",
    cursor: "default",
  },
  title: {
    color: _theme.white,
    fontWeight: "bold",
  },
  content: {
    backgroundColor: _theme.dark[50],
    color: _theme.white,
    padding: "1.4rem",
    margin: "2rem 0",
    borderRadius: 10,
  },
  smallCard: {
    padding: "1rem",
    borderRadius: "0.5rem",
    backgroundColor: _theme.dark[50],
    margin: "1rem 1rem",
    "&:hover": {
      opacity: 0.7,
    },
    cursor: "pointer",
  },
  smallCardTitle: {
    color: _theme.white,
  },
}));

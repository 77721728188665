import { createTheme } from "@mui/material/styles";
import { createMakeStyles } from "tss-react";

// // Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: "#111",
    },
    secondary: {
      main: "#19857b",
    },
  },
});

function useTheme() {
  return {
    primaryColor: "#32CD32",
    black: "#000",
    white: "#fff",
    dark: {
      40: "#2e2e2e",
      50: "#1e1e1e",
    },
  };
}

export const { makeStyles, useStyles } = createMakeStyles({ useTheme });

import { GlobalStyles as TssGlobalStyles } from "tss-react";
export const GlobalStyles = () => {
    return (
        <TssGlobalStyles
            styles={{
                "html,body ": {
                    padding: 0,
                    margin: 0,
                    fontFamily: "Arial",
                },
                "a": {
                    color: "inherit",
                    textDecoration: "none"
                },
                "*": {
                    boxSizing: "border-box"
                },
                "@media (prefers-color-scheme: dark)": {
                    "html": {
                        colorScheme: "dark",
                    },
                    "body": {
                        color: "white",
                        background: "#2a2a2a"
                    }
                }

            }}
        />
    );
};
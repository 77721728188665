import { makeStyles } from "../../helper/theme";

export const useStyles = makeStyles()((_theme) => ({
  card: {
    display: "flex",
    padding: "1rem",
    borderRadius: "0.5rem",
    backgroundColor: _theme.dark[50],
    marginBottom: "1rem",
    "&:hover": {
      opacity: 0.7,
    },
    cursor: "pointer",
  },
  image: {
    objectFit: "cover",
    borderRadius: "0.5rem",
    marginRight: "1rem",
  },
  name: {
    color: _theme.white,

    marginBottom: "0.5rem",
  },
  bigCard: {
    padding: "1rem",
    borderRadius: "0.5rem",
    backgroundColor: _theme.dark[50],
    marginBottom: "1rem",
    "& > :not(:last-child)": {
      marginBottom: "1rem",
    },
  },
}));

import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Head } from "../components/Head/Head";
import { PoemCard } from "../components/PoemCard/PoemCard";
import { useFetch } from "../hook/useFetch";

const Siir = () => {
  const { link } = useParams();
  const poem: any = useFetch(`poem/${link}`);

  if (!poem) return <div>loading...</div>;
  return (
    <>
      <Head>
        <title>{poem.title} - KAFİYE</title>
        <meta name="description" content={`${poem.author.name} tarafından yazılan ${poem.title} şiiri`} />
      </Head>
      <Grid container justifyContent="center" sx={{ padding: { xs: 10, md: 20 } }}>
        {poem && <PoemCard poem={poem} variant="large" />}
      </Grid>
    </>
  );
};

export default Siir;

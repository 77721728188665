import { Divider, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { GeneralContext } from "../../context/GeneralContext";
import { PoemCard } from "../PoemCard/PoemCard";
import { PoetCard } from "../PoetCard/PoetCard";
import { useStyles } from "./HomePage.style";

export const HomePage = () => {
    const { classes } = useStyles();
    const { poets, poems } = useContext(GeneralContext);

    return (
        <div className={classes.root}>
            <main>
                <Grid justifyContent="center" alignItems="center" flexDirection="column" container>
                    <Grid item xs={3}>
                        <Divider sx={{ paddingY: 8, marginY: 3 }} classes={{ root: classes.divider }}>
                            <Typography variant="h2" fontSize={30}>
                                ŞAİR
                            </Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={11} md={8} rowSpacing={1} columnSpacing={3} container>
                        {poets &&
                            poets?.map((poet: any, key: number) => (
                                <PoetCard poet={poet} key={key} />
                            ))}
                    </Grid>
                </Grid>
                <Grid justifyContent="center" alignItems="center" flexDirection="column" container>
                    <Grid item xs={3}>
                        <Divider sx={{ paddingY: 8, marginY: 3 }} classes={{ root: classes.divider }}>
                            <Typography variant="h2" fontSize={30}>
                                ŞİİR
                            </Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={11} md={8} rowSpacing={2} columnSpacing={3} container>
                        {poems && poems?.map((poem: any, key: number) => (
                            <PoemCard key={key} poem={poem} halfView />
                        ))}
                    </Grid>
                </Grid>
            </main>
        </div>
    );
};

// import { Adb, Menu as MenuIcon } from '@mui/icons-material';
import {
    AppBar,
    Box,
    Container,
    Toolbar,
    Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GeneralContext } from '../../context/GeneralContext';
import { useContext, useMemo } from 'react';
// import { useStyles } from './Header.style';
import { Link } from "react-router-dom";
import { lineParse } from '../../helper/utils';

// import { useState } from 'react';
// import { Image } from '../Image/Image';
// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const selectPoemLine = (poems?: any[]): any => {
    if (!poems) {
        return { poet: {}, poem: {}, poemLine: "" };
    }
    const poem = poems?.[Math.ceil((Math.random() * 100)) % poems?.length];
    const lines: any[] = lineParse(poem?.content, 0);
    const poemLine = lines?.filter(e => e?.length > 20)?.[Math.ceil((Math.random() * 100)) % lines?.length];
    if (!(poemLine?.length > 20)) {
        return selectPoemLine(poems);
    }
    return { poem, poemLine };
};

export const Header = () => {
    // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    // const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const { poems } = useContext(GeneralContext);
    const { poem, poemLine } = useMemo(() => selectPoemLine(poems), [poems]);
    // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElNav(event.currentTarget);
    // };
    // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    return (
        <AppBar position="static"
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Grid2
                        sx={{
                            pr: 3,
                            display: { xs: 'none', md: 'flex' },
                        }}
                        component={Link}
                        to="/"
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            KAFİYE
                        </Typography>
                    </Grid2>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu> */}
                    </Box>
                    <Typography
                        component={Link}
                        to="/"
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        KAFİYE
                    </Typography>
                    <Box sx={{
                        textAlign: 'center',
                        flex: 1,
                        display: { xs: 'none', md: 'block' }
                    }}>
                        {poemLine && (
                            <Link to={`/siir/${poem?.link}`}>
                                “{poemLine}” - {poem?.title} / {poem?.author?.name}
                            </Link>
                        )}
                    </Box>
                    <Grid2
                        sx={{
                            pr: 3,
                            display: { xs: 'none', md: 'flex' },
                        }}
                        component={Link}
                        to="/"
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                fontWeight: 700,
                                mr: 2,
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                                color: "#111",
                            }}
                        >
                            KAFİYE
                        </Typography>
                    </Grid2>
                    {/* <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            open={Boolean(anchorElUser)}
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleCloseUserMenu}
                            keepMounted
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
import { Grid, Typography } from "@mui/material";
import { Image } from "../Image/Image";
import { useStyles } from "./PoemCard.style";
import { Link } from "react-router-dom";
import { lineParse } from "../../helper/utils";

export interface PoemCardProps {
  poem: any;
  variant?: "small" | "large";
  halfView?: boolean;
}

export const PoemCard = ({
  poem,
  variant = "large",
  halfView
}: PoemCardProps) => {
  const { classes } = useStyles();
  const content = halfView ? lineParse(poem.content, 2) : poem.content;

  return (
    <Grid xs={12}
      md={variant === "large" && 6}
      item>
      {variant === "large" ? (
        <Grid
          xs={12}
          item
          alignItems="center"
          className={classes.card}
          flexDirection="column"
        >
          <Typography
            variant="inherit"
            className={classes.title}
            {...(halfView ? {
              component: Link,
              to: `/siir/${poem.link}`
            } : {})}
          >
            {poem.title}
          </Typography>
          <Grid justifyContent="center" container className={classes.content}>
            <Typography component="pre">
              {content}
            </Typography>
          </Grid>
          <Grid
            justifyContent="end"
            alignItems="center"
            component={Link}
            to={`/sair/${poem?.author?.link}`}
            container
          >
            <Grid item>
              <Image
                src={poem?.author?.image}
                alt={poem?.author?.name}
                width={50}
                height={50} />
            </Grid>
            <Grid sx={{ pl: 2 }} item>
              {poem?.author?.name}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          xs={12}
          className={classes.smallCard}
          component={Link}
          to={`/siir/${poem.link}`}
          justifyContent="space-between"
          alignItems="center"
          item
          container
        >
          <Typography variant="inherit" className={classes.smallCardTitle}>
            {poem.title}
          </Typography>
          <Typography variant="inherit" textAlign="right">
            OKU
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';

export const Image = ({
  src,
  alt,
  width,
  height,
  aspectRatio = '1/1',
  ...rest
}: any) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(ps => ps ? ps : true);
    }, 800);
  }, []);


  const onLoad = () => {
    setVisible(true);
  };


  return (
    <>
      {src && !visible && (
        <Skeleton
          variant="rectangular"
          width={width || height || 100}
          height={height || width || 100}
        />
      )}
      <Grid
        {...rest}
        sx={{
          width,
          height,
          aspectRatio,
          ...((!visible && {
            visibility: 'hidden',
            height: 0,
          }) ||
            {}),
        }}
      >
        <img
          src={src}
          alt={alt}
          onLoad={onLoad}
          // width={width}
          // height={height}
          style={{
            ...(width > height ? { width } : { height }),
            maxWidth: width || height || '100%',
            objectFit: 'cover',
          }}
        />
      </Grid>
    </>
  );
};

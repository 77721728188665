import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Head } from "../components/Head/Head";
import { PoemCard } from "../components/PoemCard/PoemCard";
import { PoetCard } from "../components/PoetCard/PoetCard";
import { useFetch } from "../hook/useFetch";

const Sair = (props: any) => {
  const { link } = useParams();
  const poet: any = useFetch(`poet/${link}`);

  if (!poet) return <div>loading...</div>;

  return (
    <>
      <Head>
        <title>{poet.name} - KAFİYE</title>
        <meta name="description" content={`${poet.name} hayatı şiirleri ve hakkındaki her şey`} />
      </Head>
      <PoetCard
        poet={{ ...(poet || {}) }}
        variant="large"
      />
      <Grid container justifyContent="center" sx={{ paddingX: { xs: 10, md: 20 } }}>
        {poet && poet?.poems?.map((poem: any, key: number) => (
          <PoemCard poem={poem} key={key} variant="small" />
        ))}
      </Grid>
    </>
  );
};

export default Sair;
import { Route, Routes } from 'react-router-dom';
import { RoutesType } from '../model/RoutesType';

export const createRoute = (routes: RoutesType[]) => (
  <Routes>
    {routes.map((route, key) => (
      <Route key={key} path={route.path} element={route.element} />
    ))}
  </Routes>
);

import axios from "axios";
import { useEffect, useRef, useState } from "react";

const getData = (path: any): any => {
  let data: any = localStorage.getItem(path);
  if (!data) {
    data = axios.get(
      "https://us-central1-sahin-yazilim.cloudfunctions.net/api/kafiye/" + path
    );
    data.then((res: any) => {
      if (res?.data?.data) {
        localStorage.setItem(path, JSON.stringify(res.data.data));
      }
    });
  } else {
    data = new Promise((r) =>
      r({
        data: {
          data: JSON.parse(data),
        },
      })
    );
  }

  return data;
};

export const useFetch = (path: any) => {
  const [data, setData] = useState(undefined);
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      getData(path).then((data: any) => {
        setData(data.data.data);
      });
    }
  }, [path]);

  return data;
};

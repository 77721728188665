import React from "react";

import { Helmet } from "react-helmet";

export const Head = ({ children }: any) => {
    return (
        <Helmet>
            {children}
        </Helmet>
    );
};

import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Image } from "../Image/Image";
import { useStyles } from "./PoetCard.style";

export interface PoetCardProps {
  poet: any;
  variant?: "small" | "large";
}

export const PoetCard = ({
  poet,
  variant = "small",
}: PoetCardProps) => {
  const { classes } = useStyles();

  return (
    <Grid xs={6} md={4} item>
      {variant !== "large" ? (
        <Grid
          xs={12}
          item
          className={classes.card}
          alignItems="center"
          component={Link}
          to={`/sair/${poet?.link}`}
        >
          <Image
            src={poet?.image}
            alt={poet?.name}
            width={50}
            height={50}
            className={classes.image}
          />
          <Typography variant="inherit" className={classes.name}>
            {poet?.name}
          </Typography>
        </Grid>
      ) : (
        <Grid
          xs={12}
          item
          className={classes.bigCard}
          flexDirection="column"
          alignItems="center"
          container
        >
          <Image
            src={poet?.image}
            alt={poet?.name}
            width={150}
            height={150}
            className={classes.image}
          />
          <Typography variant="inherit" className={classes.name}>
            {poet?.name}
          </Typography>
          <Typography variant="inherit" className={classes.name}>
            Şiir sayısı: {poet?.poems?.length}</Typography>
        </Grid>
      )}
    </Grid >
  );
};
